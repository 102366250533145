@use "src/styles/settings" as *;
.OwnerLink {
  color: colors(gray-900);
  text-decoration: underline;
  &:hover {
    color: colors(gray-800);
  }
  &:active {
    color: colors(gray-700);
  }
}
.TermSeparator {
  margin: 0 .5rem;
}
.Unregistered {
  color: colors(gray-400);
}
